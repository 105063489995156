import { isBrowser } from "services/general"
import { surveyZendeskTemplate } from "../utils/surveyZendeskTemplate"

import {
  GATSBY_MAKE_PB_OUTBOUND_TLC_SURVEY_EXTRACTOR,
  GATSBY_ENV
} from "gatsby-env-variables"

import axios from "axios"

export const sendMedEnsureForm = async (config) => {
  let { medEnsureData, errorCallback, callback } = config
  let organization

  if (isBrowser()) {
    organization = sessionStorage.getItem("organization")
  }

  try {
    let name = `${medEnsureData.firstName} ${medEnsureData.lastName}`

    let organizationTag = {
      "sitel": "stl",
      "maxicare": "mxc",
      "accenture": "acn"
    }

    let tags = [organizationTag[`${organization}`], "outbound", "tlc"]
    if (GATSBY_ENV !== "production") tags.push("test")

    await axios.post(GATSBY_MAKE_PB_OUTBOUND_TLC_SURVEY_EXTRACTOR, {
      ...medEnsureData,
      organization: organization,
      subject: `${name}: Teleconsult Request`,
      body: surveyZendeskTemplate({
        medEnsureData,
      }),
      tags,
    })

    if (callback) callback()
  } catch (error) {
    console.log(error)
    if (errorCallback) errorCallback()
  }
}
