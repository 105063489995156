export const surveyZendeskTemplate = (data) => {
  let {
    employeeNumber,
    firstName,
    lastName,
    consultReason,
    otherReason,
    needsRefill,
    medicinesCancelled,
    lastDoctorVisit,
    height,
    weight,
    diagnosisMedicalConditions,
    specifyDiagnosisMedicalConditions,
    lastBloodPressureCheck,
    usualBloodPressure,
    highestBloodPressure,
    hasFamilyHistoryOfDiseases,
    otherFamilyMedicalConditions,
    frequencyOfPhysicalActivities,
    isCurrentlySmoking,
    whenStartedSmoking,
    numberOfSticksPerDay,
    hasHistoryOfSmoking,
    whenStartedSmokingHistoryOfSmoking,
    numberOfSticksPerDayHistoryOfSmoking,
    whenStoppedSmokingHistoryOfSmoking,
    frequencyOfDrinkingAlcohol,
    hasHistoryOfDrinkingAlcohol,
    whenStartedDrinkingAlcohol,
    whenStoppedDrinkingAlcohol,
    teleconsultSchedule,
  } = data.medEnsureData

  let medicineCancellation = needsRefill === "I want to cancel some of my medicines under MedEnsure"
    ? medicinesCancelled
    : "N/A"
  
  let currentConditions = `${diagnosisMedicalConditions}, ${specifyDiagnosisMedicalConditions}`

  let smokingStartDate = isCurrentlySmoking === "Yes"
    ? whenStartedSmoking
    : hasHistoryOfSmoking === "Yes"
      ? whenStartedSmokingHistoryOfSmoking
      : "N/A"
  
  let numberOfSticksSmoked = isCurrentlySmoking === "Yes"
    ? numberOfSticksPerDay
    : hasHistoryOfSmoking === "Yes"
      ? numberOfSticksPerDayHistoryOfSmoking
      : "N/A"
  
  let smokingStopDate = isCurrentlySmoking === "No" && hasHistoryOfSmoking === "Yes"
    ? whenStoppedSmokingHistoryOfSmoking
    : "N/A"

  let drinkingStartDate = hasHistoryOfDrinkingAlcohol === "Yes"
    ? whenStartedDrinkingAlcohol
    : "N/A"
  
  let drinkingStopDate = hasHistoryOfDrinkingAlcohol === "Yes"
    ? whenStoppedDrinkingAlcohol
    : "N/A"

  return `
    <p>
    - Patient Details -<br/>
    Employee ID: ${employeeNumber}<br/>
    First Name: ${firstName}<br/>
    Last Name: ${lastName}<br/><br/>
  
    - Medical History -<br/>
    Reason for Consultation: ${consultReason}<br/>
    Other Reason: ${otherReason || "N/A"}<br/>
    Needs Refills: ${needsRefill}<br/>
    Medicines to Cancel: ${medicineCancellation}<br/>
    Last Doctor Visit: ${lastDoctorVisit}<br/>
    Height: ${height}<br/>
    Weight: ${weight}<br/>
    Current Conditions: ${currentConditions}<br/>
    Last Blood Pressure Check: ${lastBloodPressureCheck}<br/>
    Usual Blood Pressure: ${usualBloodPressure}<br/>
    Highest Blood Pressure: ${highestBloodPressure}<br/>
    Hypertension, Diabetes, or High Cholesterol Family History: ${hasFamilyHistoryOfDiseases}<br/>
    Family Medical History: ${otherFamilyMedicalConditions}<br/>
    Frequency of Physical Activities: ${frequencyOfPhysicalActivities}<br/>
    Do you currently smoke? ${isCurrentlySmoking}<br/>
    Do you have a history of smoking? ${hasHistoryOfSmoking || "N/A"}<br/>
    When did you start smoking? ${smokingStartDate}<br/>
    How many sticks do you smoke per day? ${numberOfSticksSmoked}<br/>
    When did you stop smoking? ${smokingStopDate}<br/>
    How often do you drink alcohol? ${frequencyOfDrinkingAlcohol}<br/>
    Do you have a history of drinking alcohol? ${hasHistoryOfDrinkingAlcohol || "N/A"}<br/>
    When did you start drinking alcohol? ${drinkingStartDate}<br/>
    When did you stop drinking alcohol? ${drinkingStopDate}<br/>
    Teleconsult Schedule: ${teleconsultSchedule}<br/>
    </p>
    `
}
