import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { get } from "lodash"
import classNames from "classnames"

import Layout from "layout"
import Section from "elements/Section"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import FileThumbnail from "elements/UploadDocumentDropzone/FileThumbnail"
import EditDetailsButton from "elements/EditDetailsButton"

import { isBrowser } from "services/general"
import { MedEnsureContext } from "../MedEnsureContext/MedEnsureContext"
import { sendMedEnsureForm } from "../services/medensure"

import styles from "../Summary/utils/bookingSummary.module.scss"

let { parseFormField } = require("services/airtable")

const MedEnsureSummary = ({ pageContext }) => {
  const { nextPath } = pageContext
  const { medEnsureState, medEnsureDispatch } = useContext(MedEnsureContext)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isBrowser()) {
      medEnsureDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    }
  }, [medEnsureDispatch])

  let sectionSummaryFields = parseFormField(
    pageContext.summaryFields.map((formField) => ({
      ...formField,
      section: formField.summarySection,
    }))
  )

  sectionSummaryFields = sectionSummaryFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  const editKey = sectionSummaryFields.reduce(
    (prev, section) => ({ ...prev, [section.section]: `${section.link}` }),
    {}
  )

  const handleSuccessSubmit = async () => {
    setLoading(false)
    if (isBrowser()) medEnsureDispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })

    navigate(nextPath, {
      state: { email: medEnsureState?.email, channel: medEnsureState?.channel },
    })
  }

  const handleErrorSubmit = () => {
    setLoading(false)
  }

  const handleSubmit = async () => {
    setLoading(true)

    await sendMedEnsureForm({
      medEnsureData: medEnsureState,
      callback: handleSuccessSubmit,
      errorCallback: handleErrorSubmit,
    })
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.title}
      pageContext={pageContext}
      verifyPage
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered>
        {sectionSummaryFields.map((section) => {
          return (
            <Section
              title={section?.section}
              addOns={{
                right: editKey[section?.section] ? (
                  <EditDetailsButton route={editKey[section?.section]} />
                ) : null,
              }}
            >
              <div className="table-container">
                <table class="table is-fullwidth label-size-input">
                  <tbody>
                    {section.fields.map((field) => {
                      let finalValue = null
                      switch (field.type) {
                        case "select":
                          finalValue = get(medEnsureState, field.name)
                            ? get(medEnsureState, field.name)?.label
                            : ""
                          break
                        case "date":
                          finalValue =
                            get(medEnsureState, field.name).year?.label &&
                            get(medEnsureState, field.name).year?.value
                              ? `${
                                  get(medEnsureState, field.name)?.month?.label
                                } ${
                                  get(medEnsureState, field.name)?.date?.label
                                }, ${
                                  get(medEnsureState, field.name)?.year.label
                                }`
                              : ""
                          break
                        case "address":
                          finalValue = get(medEnsureState, field.name)
                            ? `${
                                get(medEnsureState, field.name)?.streetAddress
                              }, ${
                                get(medEnsureState, field.name)?.city?.label
                              }, ${
                                get(medEnsureState, field.name)?.province?.label
                              }`
                            : ""
                          break
                        case "upload":
                          finalValue = medEnsureState.documents?.map(
                            (document) => <FileThumbnail file={document} />
                          )
                          finalValue = !finalValue.length && null
                          break
                        case "schedule":
                          finalValue = get(medEnsureState, field.name)
                            ? `${
                                get(medEnsureState, field.name)?.day?.label
                              }, ${
                                get(medEnsureState, field.name)?.time?.label
                              }`
                            : ""
                          break
                        case "checkbox":
                          finalValue = medEnsureState[field.name].join(", ")
                          break
                        default:
                          finalValue = get(medEnsureState, field.name)
                          break
                      }

                      if (!!finalValue)
                        return (
                          <tr>
                            <td>{field.summaryLabel}</td>
                            <td
                              className={classNames(
                                "has-text-weight-bold",
                                styles["summary__tableData"]
                              )}
                            >
                              {finalValue}
                            </td>
                          </tr>
                        )
                      return null
                    })}
                  </tbody>
                </table>
              </div>
            </Section>
          )
        })}

        <ActionButtons
          back={{ label: "Back", link: pageContext.backPath }}
          next={{
            label: "Request Teleconsult",
            loading,
            callback: handleSubmit,
          }}
        />
      </Container>
    </Layout>
  )
}

export default MedEnsureSummary
